// 1 首页
export const HOME = "/";

// 2 产品服务
export const PRODUCT = "product";

// 3 微甄房小程序
export const WZAPP = "wzapplet";

// 4 微甄房客户管理系统
export const WZCUSTOMER = "wzcrm";

// 5 微观全景
export const WZPANORAMA = "wzvr";

// 6 微观世界3D地图
export const WZMAP = "wzmap";

// 7 关于我们
export const ABOUT = "about";

// 8 合作伙伴
export const PARTNER = "partner";

// 9 联系我们
export const CONTACT = "contact";

// 10 大事记
export const EVENT = "event";

// 11 新闻咨询
export const NEWS = "news";

// 12 门店服务
export const STORE = "store";
