<template>
  <div class="SidebarNav" v-show="backToTopVisible">
    <div class="customer-service">
      <el-popover
        placement="left"
        width="140"
        trigger="hover"
        :open-delay="100"
      >
        <img
          src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/wxzx.png"
          alt=""
          style="height: 120px"
        />
        <p
          style="
            text-align: center;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #868686;
          "
        >
          微信咨询
        </p>
        <div
          class="online-consulation-trigger"
          slot="reference"
          @mouseover="changeImage(true)"
          @mouseout="changeImage(false)"
        >
          <img
            v-if="!isWxHovered"
            src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/wx--1%402x.png"
          />
          <img
            v-else
            src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/wx-1021.png"
          />
          <span>微信号</span>
        </div>
      </el-popover>
      <el-popover
        placement="left"
        width="140"
        trigger="hover"
        :open-delay="100"
      >
        <img
          src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/gzgzh.png"
          alt=""
          style="height: 120px"
        />
        <p
          style="
            text-align: center;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #868686;
          "
        >
          关注公众号
        </p>
        <div
          class="online-consulation-trigger"
          style="border-top: 1px solid #f4f0f0"
          slot="reference"
          @mouseover="changeImagegzh(true)"
          @mouseout="changeImagegzh(false)"
        >
          <img
            v-if="!isGzhHovered"
            src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/gzh1%402x.png"
          />
          <img
            v-else
            src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/gzh-1021.png"
          />
          <span>公众号</span>
        </div>
      </el-popover>
      <el-popover
        placement="left"
        width="200"
        trigger="hover"
        :open-delay="100"
      >
        <p
          style="
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #000000;
          "
        >
          服务热线：
        </p>
        <a
          style="
            font-family: Source Han Sans CN;
            font-weight: 800;
            font-size: 22px;
            color: #1eaba0;
            border-bottom: 2px solid #1eaba0;
            cursor: auto;
          "
        >
          400-0299-007
        </a>
        <div
          class="online-consulation"
          style="border-top: 1px solid #f4f0f0"
          slot="reference"
          @mouseover="changeImagekf(true)"
          @mouseout="changeImagekf(false)"
        >
          <img
            v-if="!isAppHovered"
            src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/kf-1%402x.png"
          />
          <img
            v-else
            src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/zxdh-1021.png"
          />
          <span>咨询电话</span>
        </div>
      </el-popover>
      <div
        class="online-consulation"
        slot="reference"
        @click="scrollToTop"
        style="border-top: 1px solid #f4f0f0"
        @mouseover="changeImagezd(true)"
        @mouseout="changeImagezd(false)"
      >
        <img
          v-if="!isZdHovered"
          src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/%E7%BD%AE%E9%A1%B6%20%282%29%402x.png"
        />
        <img
          v-else
          src="https://shanghai-house-model.oss-cn-shanghai.aliyuncs.com/GWICON/zd-1021.png"
        />
        <span>置顶</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navigatorIndex: -1,
      backToTopVisible: false,
      isWxHovered: false,
      isGzhHovered: false,
      isAppHovered: false,
      isZdHovered: false,
    };
  },
  computed: {},
  methods: {
    changeImage(hover) {
      this.isWxHovered = hover;
    },
    changeImagegzh(hover) {
      this.isGzhHovered = hover;
    },
    changeImagekf(hover) {
      this.isAppHovered = hover;
    },
    changeImagezd(hover) {
      this.isZdHovered = hover;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      this.backToTopVisible = scrollTop >= 200;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
// 侧边导航
.SidebarNav {
  cursor: pointer;

  .customer-service {
    position: fixed;
    right: 0;
    bottom: 150px;
    z-index: 10000;
    display: block;

    transition: all 0.7s;

    .online-consulation-item {
      display: flex;
      margin-bottom: 16px;
    }

    .title {
      font-size: 14px;
      font-weight: 700;
      color: #141419;
    }

    .qr-code {
      width: 88px;
      height: 88px;
      margin-top: 4px;
      margin-right: 16px;
    }
  }

  .online-consulation-trigger {
    height: 78px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #999999ff;
    background: #ffffff;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(214,212,212,0.75);

    padding: 12px;

    img {
      margin-bottom: 8px;
      height: 34px;
    }
  }

  .online-consulation-trigger:hover {
    background-color: #16bbadff;
    color: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(214,212,212,0.75);
  }
  .online-consulation:hover {
    background-color: #16bbadff;
    color: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(214,212,212,0.75);
  }
  .online-consulation {
    height: 78px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #999999ff;
    background: #ffffff;
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 0px rgba(214,212,212,0.75);
    img {
      margin-bottom: 8px;
      height: 29px;
    }
  }

  .back-to-top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 68px;
    border-radius: 50%;
    margin-top: 20px;
    background: #f4f0f0;

    position: initial;
    width: unset;
    cursor: pointer;
    box-shadow: none;
  }

  .side-item {
    width: 100%;
    height: 72px;
    font-size: 12px;
    display: flex;
    color: #999;
    border-bottom: 1px solid #f4f0f0;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
    }

    &:hover {
      cursor: pointer;
      color: #0dd8bd;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.4s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  // 添加微信
  .addWechat {
    position: fixed;
    top: 200px;
    right: 80px;
    width: 130px;
    font-size: 12px;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);

    .img {
      width: 100%;
      height: auto;
    }

    &:after {
      border: solid transparent;
      content: " ";
      height: 0;
      left: 100%;
      position: absolute;
      width: 0;
      border-width: 8px;
      border-left-color: #fff;
      top: 48%;
    }
  }

  .addTiktok {
    top: 255px;
  }

  .addService {
    top: 320px;
  }
}
</style>
