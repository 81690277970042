import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/Home.vue";
import {
  HOME,
  PRODUCT,
  WZAPP,
  WZCUSTOMER,
  WZPANORAMA,
  WZMAP,
  ABOUT,
  PARTNER,
  NEWS,
  EVENT,
  CONTACT,
  STORE,
} from "./constant.js";
import { COMMON_TDK } from "./commonTDK";
import { setTDK } from "@/utils/setTDK";

// 重写VueRouter的push方法
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: HOME,
    component: HomeView,
    children: [
      {
        path: "/",
        name: "Index",
        meta: {
          title: "微观世界-微筑新视界，观瞰传万家",
          content: {
            keywords: COMMON_TDK.Keywords,
            description: COMMON_TDK.Description,
          },
        },
        component: () => import("@/views/layout/index.vue"),
      },
      {
        path: PRODUCT,
        component: () => import("@/views/product/Product.vue"),
        children: [
          {
            path: WZAPP,
            name: "WzApplet",
            meta: {
              title:
                "微甄房小程序 - 抖音微信双平台覆盖，手机便捷查看房产、地图与客户信息",
              content: {
                keywords:
                  "微甄房小程序, 抖音小程序, 微信小程序, 房产信息查询, 地图导航, 客户信息, 直播数据管理, 3D模型查看, 日照分析, 学区看房, 地铁看房",
                description:
                  "微甄房小程序，集房产信息、地图导航、客户信息及直播数据管理于一体。支持手机查看3D模型、日照分析，特色学区、地铁看房功能，让您随时随地掌握房产动态，享受便捷高效的看房体验。",
              },
            },
            component: () => import("@/views/product/WzApplet.vue"),
          },
          {
            path: WZCUSTOMER,
            name: "WzCrm",
            meta: {
              title:
                "微甄房客户管理系统 - 高效CRM平台，全面管理客户留资、订单与团队协作",
              content: {
                keywords:
                  "微甄房客户管理系统, CRM系统, 客户管理, 房产行业, 客户留资, 订单管理, 团队协作, 直播数据管理, 员工管理, 精准营销",
                description:
                  "微甄房客户管理系统，专为房产行业打造的全方位CRM解决方案。轻松管理客户留资信息、订单状态、团队协作及直播数据，实现员工绩效一目了然。提升工作效率，助力企业精准营销与决策。",
              },
            },
            component: () => import("@/views/product/WzCrm.vue"),
          },
          {
            path: WZPANORAMA,
            name: "WzVr",
            meta: {
              title:
                "微观世界VR全景 - 百亿像素城市精选，3D地图直播看房新高度 | 3倍7倍高清图展示",
              content: {
                keywords:
                  "微观世界VR全景, 百亿像素, 城市精选, 3D地图直播, 直播看房, 3倍高清图, 7倍高清图, 房产展示, 购房者体验",
                description:
                  "微观世界VR全景，以百亿像素技术打造城市房产精选展示。结合3D地图，实现沉浸式直播看房体验。支持3倍、7倍高清图制作，细节尽显。",
              },
            },
            component: () => import("@/views/product/WzVr.vue"),
          },
          {
            path: WZMAP,
            name: "WzMap",
            meta: {
              title: "微观世界3D地图 - 线上直播获客神器 | 房产中介高效营销工具",
              content: {
                keywords:
                  "微观世界3D地图, 线上直播地图, 房产中介, 获客神器, 高效营销, 3D互动地图, 房源展示, 抖音直播地图，在线留资",
                description:
                  "微观世界3D地图，专为房产中介打造的线上直播地图解决方案。通过3D互动地图，实现房源直观展示与线上直播获客，助力房产中介轻松提升客户转化率，开启高效营销新时代。",
              },
            },
            component: () => import("@/views/product/WzMap.vue"),
          },
        ],
      },
      {
        path: ABOUT,
        component: () => import("@/views/about/About.vue"),
        children: [
          {
            path: PARTNER,
            name: "Partner",
            meta: {
              title: "微观世界合作伙伴 - 携手共创，共赢房产科技新时代",
              content: {
                keywords: COMMON_TDK.Keywords,
                description: COMMON_TDK.Description,
              },
            },
            component: () => import("@/views/about/Partner.vue"),
          },
          {
            path: EVENT,
            name: "Event",
            meta: {
              title: "微观世界大事记 - 记录成长瞬间，共鉴辉煌历程",
              content: {
                keywords: COMMON_TDK.Keywords,
                description: COMMON_TDK.Description,
              },
            },
            component: () => import("@/views/about/Event.vue"),
          },
          {
            path: CONTACT,
            name: "Contact",
            meta: {
              title: "联系我们 - 微观世界",
              content: {
                keywords: COMMON_TDK.Keywords,
                description: COMMON_TDK.Description,
              },
            },
            component: () => import("@/views/about/Contact.vue"),
          },
        ],
      },
      {
        path: NEWS,
        name: "News",
        meta: {
          title: "新闻咨询 - 微观世界",
          content: {
            keywords: COMMON_TDK.Keywords,
            description: COMMON_TDK.Description,
          },
        },
        component: () => import("@/views/news/index.vue"),
      },
      {
        path: "newsdetail/:id",
        name: "NewsDetail",
        meta: {
          content: {
            keywords: COMMON_TDK.Keywords,
            description: COMMON_TDK.Description,
          },
        },
        component: () => import("@/views/news/NewsDetail.vue"),
      },
      {
        path: STORE,
        name: "Store",
        meta: {
          title: "微观世界门店服务 - 线下合作门店，为您提供专业房产服务",
          content: {
            keywords: COMMON_TDK.Keywords,
            description: COMMON_TDK.Description,
          },
        },
        component: () => import("@/views/store/index.vue"),
      },
    ],
  },
  {
    path: "*",
    component: (resolve) => require(["@/components/NotFound.vue"], resolve),
    meta: {
      title: "404 | 微观世界-微筑新视界，观瞰传万家",
      content: {
        keywords: COMMON_TDK.Keywords,
        description: COMMON_TDK.Description,
      },
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  // 配置滚动行为，跳转到新的路由界面滚动条的位置
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

// 添加全局守卫
router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.meta.content) {
    let metaContent = to.meta.content;
    setTDK(metaContent.keywords, metaContent.description)
  }

  next();
});

export default router;
