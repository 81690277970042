import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "@/assets/less/common.less";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

/*
 * 重写 ElementUI ELMessage 的默认配置
 * 主要为了解决 z-index 问题
 * */
const messages = ["success", "warning", "info", "error"];
messages.forEach((type) => {
  ElementUI.Message[type] = (options) => {
    if (typeof options === "string") {
      options = {
        message: options,
      };
      options.duration = 1500;
    }
    if (options) {
      options.type = type;
      // -> App.vue
      options.customClass = "custom-el-message";
      options.offset = 80;
    }
    return ElementUI.Message(options);
  };
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
