<template>
  <div class="home">
    <nav-page></nav-page>
    <router-view />
    <nav-footer></nav-footer>
    <side-nav></side-nav>
  </div>
</template>

<script>
import NavPage from "../components/NavPage.vue";
import NavFooter from "../components/NavFooter.vue";
import SideNav from "@/components/SideNav.vue";

// HOME 页面默认路由
export default {
  components: {
    NavPage,
    NavFooter,
    SideNav,
  },
};
</script>

<style></style>
